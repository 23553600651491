<template>
  <!-- eslint-disable -->
  <div v-if="pass1 && pass2">
    <InputText type="text" v-model="id" placeholder="User ID" class="p-m-1" />
    <InputNumber
      v-model="order"
      :useGrouping="false"
      placeholder="Order Number"
      class="p-m-1"
    />
    <Button label="Look out" class="p-button-warning p-m-1" @click="look" />
  </div>
  <div v-if="result">
    <h2 class="p-ml-3">Name: {{ user.name }}</h2>
    <h2 class="p-ml-3">Email: {{ user.email }}</h2>
    <h2 class="p-ml-3">Phone number: {{ user.phone }}</h2>
    <div v-for="(item, index) in cartItems" :key="index">
      <div v-show="item.completed === 'no'">
        <CartItem
          :photo="item.photo"
          :item="item.item"
          :price="item.price"
          :color="item.color"
          :message="item.message"
          :index="index"
          :cart="false"
          :order="order"
          :id="id"
          @done="look"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import getCart from "../modules/getCart";
import { onMounted } from "vue";
import useLogin from "../modules/useLogin";
import CartItem from "../components/cartItem";
import getInfo from "../modules/getInfo";
export default {
  components: { CartItem },
  props: ["pass1", "pass2"],
  setup(props) {
    const error = ref(null);
    const id = ref(null);
    const order = ref(null);
    const result = ref(null);
    const cartItems = ref([]);
    const user = ref(null);
    onMounted(async () => {
      if (props.pass1 && props.pass2) {
        error.value = await useLogin(
          process.env.VUE_APP_EMAIL,
          process.env.VUE_APP_PASSWORD
        );
        if (error.value) {
          window.alert(error.value);
        }
      } else {
        window.alert("You are not Allowed");
      }
    });
    const look = async () => {
      const { info, getInfoError } = await getInfo(id.value);
      if (!getInfoError.value) {
        user.value = info.value;
      } else {
        window.alert(getInfoError);
      }
      let doc = `${id.value}/orders/${order.value}`;
      const { item, getCartError } = await getCart(doc);
      if (getCartError.value) {
        console.log(getCartError.value);
        window.alert("Try again");
      } else {
        if (item.value) {
          result.value = true;
          cartItems.value = item.value;
        }
      }
    };
    return { id, order, look, result, cartItems, user };
  },
};
</script>

<style scoped></style>
