import { ref } from "vue";
import { db } from "../firebase/config";
const getInfo = async (document) => {
  const getInfoError = ref(false)
  const info = ref(null)
  try {
      const res = await db.collection('users').doc(document).get()
      if (res) {
          info.value = res.data()
      }
  } catch (error) {
      getInfoError.value = error.message
  }
  return {info, getInfoError}
}
export default getInfo